import {Injectable} from '@angular/core';
import {UserService} from './user.service';

@Injectable({
    providedIn: 'root'
})
export class SoundService {

    constructor(private userService: UserService) {
    }

    public playCustomSound(url: string) {
        if (this.canPlaySound()) {
            const audio = new Audio(url);
            audio.load();
            audio.play();
        }
    }

    public playNotification() {
        if (this.canPlaySound()) {
            const audio = new Audio('../../../assets/sounds/notification.mp3');
            audio.play();
        }
    }

    private canPlaySound() {
        // this.userService.loggedUser?.settings?.notification?.sound;
        return false;
    }

}
